import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Slide1 from './slide1';
import { Wrapper } from '../../../_templates/_styles';
import NextChapter from '../../../../../components/mobile/nextChapter/nextChapter';

const WrapperMobile = () => {
  const query = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "mobile_chapters/3-3.jpg"}) {
        childImageSharp {
            fixed(width: 1920) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
      }
    }
  `);
  return (
    <Wrapper>
      <Slide1 />
      <NextChapter preTitle="Part III" fixed={query.file.childImageSharp.fixed} />
    </Wrapper>
  );
};

export default WrapperMobile;
